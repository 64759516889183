import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import Icon from '@components/icon/Icon.tsx';
import Link from '@components/link/Link.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';
import getPage from '@functions/getPage.ts';

import WidgetI from '../../types.ts';

const renderLink: WidgetI['renderHeadAction'] = function ({ item, prop }) {
    const { listStates, loadings } = this.state;
    const { actions, storePages } = this.props;
    const { pageName, pageIds, disabled, list } = item;
    const action = actions?.find((innerAction) => innerAction.name === prop);
    const onClick = action?.onClick;
    let content = item.content;
    let className = '';

    if (pageName && !content) {
        const page = getPage({ name: pageName });

        content = page?.contentOfLink;
    }

    const Tag = pageName ? Link : 'div';
    const props = pageName
        ? {
              pageName,
              ids: pageIds,
          }
        : {
              onClick: async () => {
                  if (!disabled) {
                      if (list) {
                          this.listStateHandler({ key: item.name });
                      } else if (onClick) {
                          await this.loadingHandler({ name: item.name, loading: true });
                          try {
                              await onClick();
                          } catch (error) {}
                          await this.loadingHandler({ name: item.name, loading: false });
                      }
                  }
              },
          };

    if (item.type === 'link' && list) {
        className = list.find(
            (innerItem) => innerItem.pageName && storePages[innerItem.pageName].isShow,
        )
            ? '_current'
            : '';
    }

    return (
        <Tag
            className={`v2widget__headLink ${item.className || ''} ${disabled ? '_disabled' : ''} ${list?.length ? '_more' : ''} ${className}`}
            {...props}
        >
            <div className="v2widget__headLinkInner _ROW _CLICK">
                <LoaderBlock className="v2widget__headLinkLoader" isShow={!!loadings[item.name]} />
                {content}{' '}
                {list ? (
                    <>
                        <i className="v2widget__headLinkIcon">
                            <Icon name="more-vertical" />
                        </i>
                    </>
                ) : (
                    <></>
                )}
            </div>
            {list && (
                <>
                    <Animate className="v2widget__headLinkList" isShow={!!listStates[item.name]}>
                        {list.map((listLink) => {
                            const {
                                name: listLinkName,
                                disabled: listLinkDisabled,
                                pageName: listLinkPageName,
                                pageIds: listLinkPageIds,
                            } = listLink;
                            const listLinkOnClick = action?.list?.find(
                                (innerListLink) => innerListLink.name === listLinkName,
                            )?.onClick;

                            let listLinkContent: string | undefined = listLink.content;

                            if (!listLinkContent && listLinkPageName) {
                                const linkPage = getPage({ name: listLinkPageName });

                                listLinkContent = linkPage.contentOfLink;
                            }

                            const LinkTag = listLinkPageName ? Link : 'div';
                            const linkProps = listLinkPageName
                                ? {
                                      pageName: listLinkPageName,
                                      ids: listLinkPageIds,
                                  }
                                : {
                                      onClick: async () => {
                                          if (listLinkOnClick && !listLinkDisabled) {
                                              await this.loadingHandler({
                                                  name: item.name,
                                                  loading: true,
                                              });
                                              try {
                                                  await listLinkOnClick();
                                              } catch (error) {}
                                              await this.loadingHandler({
                                                  name: item.name,
                                                  loading: false,
                                              });
                                          }
                                      },
                                  };

                            return (
                                <LinkTag
                                    className="v2widget__headLinkListItem _CLICK"
                                    key={listLinkName}
                                    dangerouslySetInnerHTML={{ __html: listLinkContent! }}
                                    {...linkProps}
                                ></LinkTag>
                            );
                        })}
                    </Animate>
                </>
            )}
        </Tag>
    );
};

export default renderLink;
