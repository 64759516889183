import convertHEICFile from '@functions/convertHEIC.ts';
import handlerLoading from '@functions/handlerLoading.ts';
import uploadFileRequest from '@requests/uploadFile.ts';

import I from '../types.ts';

const uploadFile: I['uploadFile'] = async function ({ id, fileId }, e) {
    const file = e.target.files![0];

    const formData = new FormData();

    await handlerLoading.call(this, `file.${id}.${fileId}`);

    const { result } = await convertHEICFile(file);

    formData.set('file', result);

    try {
        const { name, src, size } = await uploadFileRequest({ formData });

        const fields = {
            doc: {
                id,
                fileId,
                name,
                src,
                size,
            },
        };

        await this.updateHandler({ name: 'docs', fields });
    } catch (error) {}

    e.target.value = '';

    await handlerLoading.call(this, undefined);
};

export default uploadFile;
