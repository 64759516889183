import React from 'react';

import CodeBox from '@components/CodeBox.jsx';
import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';
import getFormatPhone from '@functions/getFormatPhone.ts';

import phoneHandler from '../methods/steps/phoneHandler.ts';

import I, { BlockT } from '../types.ts';

const descriptions = {
    call: (
        <>
            Необходимо ввести <b>последние 4 цифры</b> телефона, с которого вам поступил звонок
        </>
    ),
    sms: (
        <>
            Необходимо ввести <b>4-значный код</b>, который поступил вам в смс
        </>
    ),
    tg: (
        <>
            Необходимо ввести <b>4-значный код</b>, который поступил вам в телеграм
        </>
    ),
} as const;

const renderBlock = function (this: I, { prop }: { prop: keyof typeof descriptions }) {
    const { savedPhone } = this.state;
    const description = descriptions[prop];

    return (
        <div className="join__blockInner _ITEM _COL">
            <div className="join__blockTitle">
                Введите код
                <br />
                подтверждения
            </div>
            <div className="join__blockText">
                {description} на <b>{getFormatPhone({ phone: savedPhone! })}</b>
            </div>
            <div className="join__blockCode">
                <CodeBox
                    isNumber={true}
                    className="_join"
                    inputs={[1, 2, 3, 4]}
                    callback={this.checkCode.bind(this)}
                />
            </div>
            {this.renderError()}
            <div
                className="join__blockBack"
                onClick={async () => {
                    await this.init({ fields: {} });

                    this.setStep('phone');
                }}
            >
                Ввести другой номер
            </div>
            <div
                className="join__blockBack"
                onClick={() => {
                    phoneHandler.call(this);
                }}
            >
                Запросить код повторно
            </div>
        </div>
    );
};

const Code: BlockT = function () {
    const { pushNotificationService } = this.state;

    return (
        <>
            <ListAbsoluteMain
                className="join__blockInners _ITEMS"
                items={[{ key: pushNotificationService }]}
                renderItem={renderBlock.bind(this)}
                classNameItem="join__blockInner"
                prop="key"
                paramsParent={{ width: true }}
                styles={['height']}
                isNotParamsItem={true}
            />
        </>
    );
};

export default Code;
