import { FilterQueryT } from '@global/types.ts';

import FilterI from '../types.ts';

const getChange: FilterI['getChange'] = function () {
    const { model: filter = [] } = this.state;
    const resultQuery: FilterQueryT[] = [];

    filter.forEach((item) => {
        if (['switch', 'input'].includes(item.type)) {
            if (item.value) {
                resultQuery.push({
                    name: item.name,
                    key: item.name,
                    value: item.value as string | boolean,
                });
            }
        } else if (['checkbox', 'select'].includes(item.type)) {
            resultQuery.push(
                ...(item.value as string[]).map((innerItem) => ({
                    name: item.name,
                    key: item.name,
                    value: innerItem,
                })),
            );
        } else if (item.type === 'date') {
            if (item.currentDate) {
                resultQuery.push({
                    name: item.name,
                    key: `startDate_${item.name}`,
                    value: item.currentDate,
                });
            }

            if (item.doubleCurrentDate) {
                resultQuery.push({
                    name: item.name,
                    key: `endDate_${item.name}`,
                    value: item.doubleCurrentDate,
                });
            }
        }
    });

    return resultQuery;
};

export default getChange;
