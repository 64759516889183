import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import handlerPopup from '../../../functions/app/handlerPopup';

import Button from '../../Button.jsx';
import Popup from './Popup.jsx';

class Start extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.hide = this.hide.bind(this);
    }

    hide() {
        const { hideCallback } = this.props;

        super.hide();

        handlerPopup({ name: 'appStartPopup', isShow: false });

        localStorage.setItem('appShowStartPopup', true);

        hideCallback();
    }

    render() {
        return (
            <div ref={this.parent} className="appPopup _col">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <img
                                src={require('../../../img/emodzi/victory-hand.png')}
                                alt=""
                                className="appPopup__icon"
                            />

                            <div className="appPopup__title">
                                Добро пожаловать
                                <br />в LIVECARGO!
                            </div>
                            <p className="appPopup__description">
                                Теперь <b>все ваши заказы —</b>
                                <br />в одном приложении.
                            </p>
                            <div className="appPopup__button">
                                <Button
                                    icon={{ type: 'end', name: 'arrow-next-2' }}
                                    isDisabled={false}
                                    onClick={this.hide}
                                >
                                    Обзор приложения
                                </Button>
                            </div>
                            <div className="appPopup__link">
                                <div className="link _click" onClick={this.hide}>
                                    Начать работу
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(Start);

Start.propTypes = {
    windowHeight: PropTypes.number,
    hideCallback: PropTypes.func,
};
