import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import handlerPopup from '../../../functions/app/handlerPopup';
import checkValueOfEmpty from '../../../functions/checkValueOfEmpty';
import signContract from '../../../functions/signContract';

import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import Button from '../../Button.jsx';
import Field from '../../Field.jsx';
import Icon from '../../Icon.jsx';
import Popup from './Popup.jsx';

class SignContract extends Popup {
    constructor(props) {
        super(props);
        this.state = {
            code: {
                value: '',
                error: null,
            },
        };

        this.handlerCode = this.handlerCode.bind(this);
        this.sign = this.sign.bind(this);

        this.hide = this.hide.bind(this);
    }

    errors = {
        'error-code': 'Неверный код. Попробуйте ещё раз',
    };

    hide() {
        super.hide();

        handlerPopup({ name: 'appSignContractPopup', isShow: false });
    }

    handlerCode({ action, value, error }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState((state) => {
                    const newState = { ...state };
                    const code = { ...newState.code };

                    if (checkValueOfEmpty(value, true)) {
                        code.value = value;
                    }

                    code.error = error || null;

                    newState.code = code;

                    return newState;
                });
            }
        });
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    async sign() {
        const { code } = this.state;
        const { value } = code;
        const { user } = this.props;
        const { contract } = user;

        this.setState({ error: null });

        if (!value) {
            this.handlerCode({ action: 'change', error: 'error' });
        } else {
            await this.handlerLoading('sign');

            try {
                await signContract({ itemsIds: [contract._id], code: value });

                this.hide();

                this.handlerLoading(null);
            } catch (error) {
                const { data } = error;
                const { message } = data;

                if (message === 'Code is incorrect') {
                    this.setState({ error: 'error-code' });
                    this.handlerCode({ action: 'change', error: 'error' });
                }

                this.handlerLoading(null);
            }
        }
    }

    render() {
        const { code, loadingKey, error } = this.state;
        const { appSignContractPopup } = this.props;

        return (
            <div ref={this.parent} className="appPopup _col _signContract">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <i className="appPopup__icon">
                                <Icon name="file-type-doc" />
                            </i>
                            <div className="appPopup__title _mediumSize">
                                {appSignContractPopup.contractName}
                            </div>
                            <p className="appPopup__description">
                                Введите код подтверждения из смс,
                                <br />
                                чтобы подписать договор
                            </p>
                            <div className="appPopup__fields">
                                <div className="appPopup__field">
                                    <Field
                                        keyName="code"
                                        name="code"
                                        value={code.value}
                                        error={code.error}
                                        callback={this.handlerCode}
                                    />
                                </div>
                            </div>
                            <div className="appPopup__button">
                                <Button onClick={this.sign} showLoader={loadingKey === 'sign'}>
                                    Подписать договор
                                </Button>
                            </div>
                            <AnimateChangeUp
                                className="appPopup__errors"
                                renderKey={error}
                                parentStyles={['height']}
                                callback={({ isChangeLen }) => {
                                    if (isChangeLen) {
                                        this.setState({ updatedBlocksKey: new Date().getTime() });
                                    }
                                }}
                            >
                                {error ? (
                                    <div className="appPopup__error">{this.errors[error]}</div>
                                ) : null}
                            </AnimateChangeUp>
                            {/* <div className="appPopup__link">
                                <div className="link _click">Не пришёл код?</div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowHeight: state.windowHeight,
        user: state.user,
        appSignContractPopup: state.appSignContractPopup,
    };
}

export default connect(mapStateToProps)(SignContract);

SignContract.propTypes = {
    windowHeight: PropTypes.number,
    user: PropTypes.object,
    appSignContractPopup: PropTypes.object,
};
