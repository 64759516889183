import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import handlerAppPopup from '../../functions/app/handlerPopup';
import getFormatedDate from '../../functions/getFormatedDate';
import getHeaders from '../../functions/getHeaders';
import getUserInfo from '../../functions/getUserInfo';
import { getCookie } from '../../functions/handlerCookies';
import handlerPopup from '../../functions/handlerPopup';
import handlerWindow from '../../functions/handlerWindow';
import setSpacesInText from '../../functions/setSpacesInText';

import Avatar from '../Avatar.jsx';
import Icon from '../Icon.jsx';
import ImageLazy from '../ImageLazy.jsx';
import CorporationInfo from '../crm/manual/CorporationInfo.jsx';
import File from './File.jsx';

const typesOfFiles = require('../../infos/typesOfFiles.json');

class Message extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerMore = this.handlerMore.bind(this);
        this.handlerActions = this.handlerActions.bind(this);
        this.rotateCallback = this.rotateCallback.bind(this);

        this.parent = React.createRef();
    }

    maxWidth = process.env.REACT_APP_SYSTEM === 'app' ? 292 : 400;

    getFileSrc(file) {
        const { updatedKey } = file;

        return `${process.env.REACT_APP_STATIC}/chats/${file.path}?${updatedKey}`;
    }

    findCardNumbers(content) {
        const { chat, message, checkMe } = this.props;
        const { anyInfo } = chat;
        const isMe = checkMe(message);

        if (process.env.REACT_APP_SYSTEM !== 'crm' || isMe || !anyInfo?.userId) {
            return content;
        }

        const match = Array.from(content.matchAll(/(?:\d)[\d ]{14,}(?:\d)/gm));
        let resultText = '';

        if (match.length === 0) {
            resultText = content;
        }
        match.forEach((item, key) => {
            const startIndex = key === 0 ? 0 : match[key - 1].index + match[key - 1][0].length;

            resultText += content.slice(startIndex, item.index);
            resultText += `<span class="_cardNumber _click">${item[0]}</span>`;

            if (key === match.length - 1) {
                resultText += content.slice(item.index + item[0].length);
            }
        });

        return resultText;
    }

    getContent(content) {
        const clearedContent = content.replace(/\n/g, ' \n ').replace(/\u00A0/g, ' ');
        const resultContent = [];

        clearedContent.split(' ').forEach((item) => {
            if (item.includes('http') || item.includes('://')) {
                resultContent.push(`<a href="${item}" target="_blank" class="_wrap">${item}</a>`);
            } else {
                resultContent.push(item);
            }
        });

        let resultText = resultContent.join(' ').replace(/\n/g, '<br/>');

        resultText = this.findCardNumbers(resultText);

        return setSpacesInText(resultText);
    }

    handlerMore({ target }) {
        const { chat, message, getParent, editMessage } = this.props;
        const { file } = message;
        const parent = getParent?.();
        const deleteMessage = () =>
            new Promise((resolve, reject) => {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/chat`,
                        {
                            id: chat._id,
                            action: 'delete-message',
                            messagesIds: [message._id],
                        },
                        {
                            headers: getHeaders(),
                        },
                    )
                    .then(
                        (res) => {
                            const { success } = res.data;

                            if (success) {
                                resolve();
                            } else {
                                reject();
                            }
                        },
                        () => null,
                    );
            });

        const editMiddleMessage = () => {
            editMessage({ id: message._id });
        };

        const items = ['deleteMessage'];

        if (!file) {
            items.unshift('editMessage');
        }

        handlerWindow({
            parent,
            parentScroll: parent?.querySelector('.chatV2Window__contentInner'),
            parentResize: parent,
            target: target.closest('.chatV2Message__more'),
            action: 'show',
            name: 'chatMessageActions',
            className: '_actions _right',
            uniqKey: `chatMessageActions-${message._id}`,
            items,
            isHideFromScroll: true,
            isCheckScrollPosition: false,
            deleteMessage,
            editMessage: editMiddleMessage,
            centers: {
                left: 1,
                top: 0,
            },
        });
    }

    handlerActions({ target }) {
        const { chat, message, getParent, user } = this.props;
        const { file } = message;
        const parent = getParent?.();
        const resultFile = {
            _id: file._id,
            path: this.getFileSrc(file),
            src: `./chats/${file.path}`,
            name: file.name,
        };

        console.log(resultFile);

        const handlerDoc = () => {
            handlerAppPopup({
                name: 'chatExecutorDocPopup',
                isShow: true,
                chatId: chat._id,
                file: resultFile,
            });
        };

        const items = ['downloadImage'];

        if (
            chat.responsible === user._id &&
            (chat.type === 'telegram' ? chat.anyInfo.userId : chat.entity.type === 'executor')
        ) {
            items.unshift('addDocForExecutor');
        }

        handlerWindow({
            parent,
            parentScroll: parent?.querySelector('.chatV2Window__contentInner'),
            parentResize: parent,
            target: target.closest('.chatV2Message__imageActions'),
            action: 'show',
            name: 'chatMessageDocActions',
            className: '_actions _right',
            uniqKey: `chatMessageDocActions-${message._id}`,
            items,
            file: resultFile,
            isHideFromScroll: true,
            isCheckScrollPosition: false,
            handlerDoc,
            centers: {
                left: 1,
                top: 0,
            },
        });
    }

    rotateCallback({ file }) {
        const { message, rotateCallback } = this.props;

        rotateCallback({ id: message._id, file });
    }

    componentDidMount() {
        const { chat, message, getParent, cardNumberCallback } = this.props;
        const parent = getParent?.();

        this.parent.current.querySelectorAll('._cardNumber').forEach((item) => {
            item.onclick = () => {
                const cardNumber = item.innerText.trim().replace(/ /gi, '');

                handlerWindow({
                    parent,
                    parentScroll: parent?.querySelector('.chatV2Window__contentInner'),
                    parentResize: parent,
                    target: item,
                    action: 'show',
                    name: 'chatMessageCardNumber',
                    className: '_actions _center',
                    uniqKey: `chatMessageCardNumber-${message._id}`,
                    chatId: chat._id,
                    cardNumber,
                    isHideFromScroll: true,
                    isCheckScrollPosition: false,
                    cardNumberCallback,
                    centers: {
                        left: 0.5,
                        top: 0,
                    },
                });
            };
        });
    }

    render() {
        const { user, message, imageLoad, chat, checkMe, notRead } = this.props;
        const { content, infoCreater, file, type, corporation } = message;
        const isMe = checkMe(message);

        let createrName = '';

        if (infoCreater) {
            if (infoCreater.nameOfRole) {
                createrName += infoCreater.nameOfRole;
                createrName += ' – ';
            }

            createrName += getUserInfo({
                type: 'doubleName',
                user: infoCreater,
            });
        }

        if (!createrName && process.env.REACT_APP_SYSTEM !== 'app') {
            createrName = 'Unknown';
        }

        const userId = getCookie('hashOrderInfo') || user?._id;

        if (userId === message.createrId) {
            createrName = 'Вы';
        }

        let isRead =
            process.env.REACT_APP_SYSTEM === 'crm'
                ? message.usersWhoRead?.includes(chat.entity.id)
                : message.isReadFromResponsible;

        if (chat.type === 'support' && chat.entity.id === user?._id) {
            isRead = message.isReadFromResponsible;
        }

        const isHorizontal = file && file.width / file.height > 1.5;
        const horizontalHeight = isHorizontal
            ? +((this.maxWidth / file.width) * file.height).toFixed(0)
            : null;
        const dateDiff =
            (new Date().getTime() - new Date(message.createdDate).getTime()) / (1_000 * 60 * 60);
        const condForMore =
            process.env.REACT_APP_SYSTEM === 'crm' &&
            isMe &&
            (chat.responsible === userId || chat.entity.id === userId) &&
            message.createrId === userId &&
            dateDiff <= 48 &&
            (chat.type !== 'telegram' || message.anyInfo?.messageId);
        const withCorporation = corporation && !isMe;

        return (
            <div
                ref={this.parent}
                className={`chatV2Message _col _${process.env.REACT_APP_SYSTEM} ${
                    file ? '_file' : ''
                } ${isMe ? '_me' : ''} ${
                    file && typesOfFiles.image.includes(file.type) ? '_image' : ''
                } ${type ? `_${type}` : ''} ${condForMore ? '_withMore' : ''} ${
                    withCorporation ? '_withCorporation' : ''
                }`}
                data-notread={notRead}
                data-id={message._id}
            >
                <div className="chatV2Message__wrapper _col">
                    <div className="chatV2Message__wrapperBox _col">
                        <div className="chatV2Message__inner">
                            {file ? (
                                <>
                                    {typesOfFiles.image.includes(file.type) && (
                                        <div className="chatV2Message__images">
                                            <div
                                                className="chatV2Message__image _click"
                                                key={file._id}
                                                style={
                                                    isHorizontal
                                                        ? {
                                                              height: `${horizontalHeight}px`,
                                                          }
                                                        : {}
                                                }
                                            >
                                                {process.env.REACT_APP_SYSTEM === 'crm' && (
                                                    <>
                                                        <div
                                                            className="chatV2Message__imageActions _col"
                                                            onClick={this.handlerActions}
                                                        >
                                                            <i className="chatV2Message__imageActionsIcon">
                                                                <Icon name="more-vertical" />
                                                            </i>
                                                        </div>
                                                    </>
                                                )}
                                                <div
                                                    className="chatV2Message__imagePreview _col"
                                                    onClick={() => {
                                                        if (
                                                            process.env.REACT_APP_SYSTEM === 'crm'
                                                        ) {
                                                            handlerPopup('galeryPopup', {
                                                                isShow: true,
                                                                files: [
                                                                    {
                                                                        type: file.type,
                                                                        src: this.getFileSrc(file),
                                                                        fullSrc:
                                                                            this.getFileSrc(file),
                                                                        id: file._id,
                                                                    },
                                                                ],
                                                                title: `Чат`,
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <i className="chatV2Message__imagePreviewIcon">
                                                        <Icon name="search" />
                                                    </i>
                                                </div>
                                                <ImageLazy
                                                    src={this.getFileSrc(file)}
                                                    className="chatV2Message__imageItem"
                                                    load={imageLoad}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {!typesOfFiles.image.includes(file.type) && (
                                        <div className="chatV2Message__files">
                                            <div className="chatV2Message__file" key={file._id}>
                                                {(() => {
                                                    const TagFile = file.duration ? 'div' : 'a';
                                                    const propsFile = file.duration
                                                        ? {}
                                                        : {
                                                              download: true,
                                                              href: `${process.env.REACT_APP_STATIC}/chats/${file.path}`,
                                                              target: '_blank',
                                                              rel: 'noreferrer',
                                                          };

                                                    return (
                                                        <TagFile
                                                            className="chatSession__itemMessageFile"
                                                            key={file._id}
                                                            {...propsFile}
                                                        >
                                                            <File
                                                                file={{
                                                                    ...file,
                                                                    src: `${
                                                                        process.env.REACT_APP_STATIC
                                                                    }/chats/${
                                                                        file.pathUpdateFile ||
                                                                        file.path
                                                                    }`,
                                                                }}
                                                                className={`_inMessage ${
                                                                    checkMe(message) ? '_me' : ''
                                                                }`}
                                                            />
                                                        </TagFile>
                                                    );
                                                })()}
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    {createrName && (
                                        <div className="chatV2Message__creater">
                                            <div className="chatV2Message__createrInner">
                                                {createrName}
                                            </div>
                                        </div>
                                    )}

                                    <div
                                        className="chatV2Message__text"
                                        dangerouslySetInnerHTML={{
                                            __html: this.getContent(content),
                                        }}
                                    ></div>
                                </>
                            )}
                            {type === 'comments' && (
                                <>
                                    <div className="chatV2Message__commentsSupport">
                                        Внутренний комментарий
                                    </div>
                                </>
                            )}

                            {isMe && !type && (
                                <div className="chatV2Message__read _row">
                                    <i className="chatV2Message__readIcon">
                                        <Icon name="chat-done" />
                                    </i>
                                    <i
                                        className={`chatV2Message__readIcon ${
                                            !isRead ? '_hide' : ''
                                        }`}
                                    >
                                        <Icon name="chat-done-read" />
                                    </i>
                                </div>
                            )}
                        </div>
                        {process.env.REACT_APP_SYSTEM === 'crm' && (
                            <div className="chatV2Message__avatar">
                                <Avatar holder={infoCreater} />
                            </div>
                        )}
                    </div>
                    <div className="chatV2Message__time">
                        {getFormatedDate({ date: new Date(message.createdDate), type: 'time' })}
                    </div>
                </div>
                {withCorporation && (
                    <div className="chatV2Message__corporation">
                        <CorporationInfo {...corporation} short={true} />
                    </div>
                )}

                {condForMore && (
                    <>
                        <div className="chatV2Message__more _click" onClick={this.handlerMore}>
                            <i className="chatV2Message__moreIcon">
                                <Icon name="more-vertical" />
                            </i>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Message);

Message.propTypes = {
    user: PropTypes.object,
    chat: PropTypes.object,
    message: PropTypes.object,
    imageLoad: PropTypes.func,
    checkMe: PropTypes.func,
    getParent: PropTypes.func,
    editMessage: PropTypes.func,
    rotateCallback: PropTypes.func,
    cardNumberCallback: PropTypes.func,
    notRead: PropTypes.bool,
};
